import { inject, Injectable, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { ApiService } from '@shared/services/api.service';
import { ToastService } from '@shared/services/toast.service';
import { map } from 'rxjs';
import { AssigmentFormAPIData, Assignment, AssignmentResponse } from './assignment.types';

@Injectable({
  providedIn: 'root',
})
export class AssignmentService {
  #api = inject(ApiService);
  #auth = inject(AuthService);
  #router = inject(Router);
  #toast = inject(ToastService);

  userId = signal<string | undefined>(this.#auth.currentUserId());

  assignments = rxResource({
    request: this.userId, // to make sure we reload on new user id
    loader: () => this.#api.get<AssignmentResponse<Assignment>>(`assignments`).pipe(map(response => response.items)),
  });

  refreshAssignmentsList(): void {
    this.assignments.reload();
  }

  deleteAssignment(assignmentId: Assignment['id']) {
    return this.#api.delete(`assignments/${assignmentId}`).subscribe({
      next: () => {
        this.refreshAssignmentsList();
      },
      error: () => {
        this.#toast.negative();
      },
    });
  }

  sendAssignmentToAPI(data: AssigmentFormAPIData) {
    return this.#api.post(`assignments`, data).subscribe({
      next: () => {
        this.refreshAssignmentsList();
        this.#router.navigate(['/assignments/overview']);
      },
      error: () => {
        this.#toast.negative();
      },
    });
  }
}
