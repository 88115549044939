import { inject, Injectable, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { AssignmentResponse, PlayerAssignment } from '@features/assignment/assignment.types';
import { SessionResponse } from '@features/session/session.types';
import { NoteBodyType, NoteType, PlayerOverview } from '@shared/types/user.types';
import { debounceTime, distinctUntilChanged, map, Subject, switchMap } from 'rxjs';
import { ApiService } from './api.service';

const EndpointConversion: Record<NoteType, string> = {
  goal: 'goal',
  remark: 'internal-remark',
};

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  #api = inject(ApiService);

  playerId = signal<string | undefined>(undefined);
  player = rxResource({
    request: this.playerId,
    loader: ({ request: id }) => this.#api.get<PlayerOverview>(`players/${id}`),
  });

  sessions = rxResource({
    request: this.playerId,
    loader: ({ request: id }) =>
      this.#api.get<SessionResponse>(`players/${id}/sessions?limit=100`).pipe(
        map(response =>
          response?.sessions.sort((a, b) => {
            if (a.status === 'active' && b.status !== 'active') {
              return -1;
            } else if (a.status !== 'active' && b.status === 'active') {
              return 1;
            } else {
              return new Date(b.timestamp_start).getTime() - new Date(a.timestamp_start).getTime();
            }
          })
        )
      ),
  });

  assignments = rxResource({
    request: this.playerId,
    loader: ({ request: id }) =>
      this.#api
        .get<AssignmentResponse<PlayerAssignment>>(`players/${id}/assignments`)
        .pipe(map(response => response.items)), // TODO: tweak default limit (5) when backend works
  });

  #playerNoteQuery = new Subject<[NoteType, string]>();
  #playerNoteQuery$ = this.#playerNoteQuery.asObservable().pipe(
    debounceTime(1000),
    map(v => {
      return [v[0], v[1].trim()] as [NoteType, string];
    }),
    distinctUntilChanged()
  );

  playerNote$ = this.#playerNoteQuery$.pipe(
    switchMap(([type, text]) =>
      this.#api.put<NoteBodyType, PlayerOverview>(`players/${this.playerId()}/${EndpointConversion[type]}`, {
        [type]: text.length ? text : null,
      })
    )
  );

  updatePlayerNote(type: NoteType, text: string): void {
    this.#playerNoteQuery.next([type, text]);
  }
}
